import axiosInstance from '~/api';
import { MIXPANEL_TRACK } from '~/api/apiUrlsV2';
import { getCookie } from '~/services/cookies.client';

const mixpanelSSTrack = async (eventName: string, properties?: Record<string, string | number | null>) => {
  let propertyParams = {} as Record<string, string | number | null>;
  if (properties != undefined) {
    propertyParams = properties;
  }
  const token = getCookie('FO-JWT');
  const searchParams = new URL(window.location.href).searchParams;
  const referrer = document.referrer;
  const utm_source = searchParams.get('utm_source');
  const utm_medium = searchParams.get('utm_medium');
  const utm_campaign = searchParams.get('utm_campaign');
  const utm_content = searchParams.get('utm_content');
  const utm_term = searchParams.get('utm_term');
  if (referrer != null && referrer != '') propertyParams['$referrer'] = referrer;
  if (utm_source != null && utm_source != '') propertyParams['utm_source'] = utm_source;
  if (utm_medium != null && utm_medium != '') propertyParams['utm_medium'] = utm_medium;
  if (utm_campaign != null && utm_campaign != '') propertyParams['utm_campaign'] = utm_campaign;
  if (utm_content != null && utm_content != '') propertyParams['utm_content'] = utm_content;
  if (utm_term != null && utm_term != '') propertyParams['utm_term'] = utm_term;
  const { data } = await axiosInstance.post(
    MIXPANEL_TRACK,
    {
      event_name: eventName,
      properties: propertyParams,
    },
    {
      headers: {
        ...(token && { Authorization: `Bearer ${token}` }),
      },
    },
  );
  if (window?.ENVIRONMENT !== 'production') {
    console.log(
      'Mixpanel SS track',
      '\nevent=',
      eventName,
      '\nproperties=',
      propertyParams,
      '\nresponse_payload=',
      data,
    );
  }
};

export default mixpanelSSTrack;
